import {useField} from 'remix-validated-form';

import {
  default as CoreSelect,
  type SelectProps,
  type ErrorState,
} from '@/components/base/modules/Select/Select';
import {useTranslations} from '@/hooks/useTranslations';

export function Select({id, ...inputProps}: SelectProps) {
  const {t} = useTranslations();
  const {error, getInputProps} = useField(id);

  let errorMessage;
  let state: ErrorState;
  if (error) {
    errorMessage = t(error);
    state = 'error';
  }

  return (
    <CoreSelect
      {...getInputProps({id: id, ...inputProps})}
      message={errorMessage}
      state={state}
    />
  );
}
